import React, { Fragment, useEffect, useRef } from "react";
import moment from "moment";
import CODES from "../../../constants/OEMReportConstants";
import { TableLoader } from "../../Shared/Table";
import ReactTable from "react-table";
import ITableState from "../../../interfaces/ITableState";
import { connect, useSelector } from "react-redux";
import { getColor, getDescription, getIcon, interlaceElements } from "../../../utils/EditHistoryUtils";
import ChevronDownIcon from "../../Shared/Icons/ChevronDownIcon";
import IClient from "../../../interfaces/IClient";
import { IOption } from "../../../interfaces/IDisplayCampaign";
import Button from "../../Shared/Button";
import { Menu, Transition } from "@headlessui/react";
import { fetchReportList } from "../../../actions/OEMReportActions";
import OEMProgram from "../../Shared/OEMProgram";
import SelectField from "../../Shared/Form/Blocks/SelectField";
import Label from "../../Shared/Form/Label";
import IColumn from "../../../interfaces/IColumn";
import { IReportFormValues } from "../../../interfaces/IReport";
import * as Actions from "../../../actions/OEMReportActions";

export interface IReportListProps {
    onFetchReports: (state: ITableState, urlFilterOptions: any) => any;
}
interface IProps {
    reportType: string;
    reportParamOemProgram: string;
    reportParamFromDate: string;
    processingStatus: string;
    createdAt: string;
    processingEndedAt: string;
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const formatTheReport = ({
    reportType,
    reportParamOemProgram,
    reportParamFromDate,
    processingStatus,
    createdAt,
    processingEndedAt,
    ...fields
}: IProps) => ({
    ...fields,
    reportType,
    reportParamOemProgram,
    reportParamFromDate,
    processingStatus: CODES[`REPORT_CODE_${processingStatus}`],
    createdAt: moment(createdAt).format(DATE_FORMAT),
    processingEndedAt: processingEndedAt ? moment(processingEndedAt).format(DATE_FORMAT) : null
});

const columns: { id: string; accessor: any; Header: string }[] = [
    {
        id: "reportParamOemProgram",
        accessor: "reportParamOemProgram",
        Header: "OEM"
    },
    {
        id: "reportType",
        accessor: "reportType",
        Header: "Report"
    },
    {
        id: "reportParamFromDate",
        accessor: "reportParamFromDate",
        Header: "Report Date"
    },
    {
        id: "processingStatus",
        accessor: "processingStatus",
        Header: "Status"
    },
    {
        id: "processingStartedAt",
        accessor: (column: any) => {
            if (!column?.processingStartedAt) return <div className={"text-sm text-gray-600"}>Hasn't started</div>;

            return <div>{moment(column.processingStartedAt).format(DATE_FORMAT)}</div>;
        },
        Header: "Processing Started At"
    },
    {
        id: "processingEndedAt",
        accessor: (column: any) => {
            if (!column?.processingEndedAt) return <div className={"text-sm text-gray-600"}>Hasn't finished</div>;

            return <div>{moment(column.processingEndedAt).format(DATE_FORMAT)}</div>;
        },
        Header: "Completed"
    },
    {
        id: "user",
        accessor: (column: any) => {
            if (!column?.user) return <div className={"text-sm text-gray-600"}>Automated Run</div>;

            return (
                <div>
                    {column?.user?.firstName} {column?.user?.lastName}
                </div>
            );
        },
        Header: "Ran By User"
    }
];

const ReportList: React.FC<IReportListProps> = ({ onFetchReports }) => {
    const tableRef = useRef(null);
    const defaultPageSize = 15;
    const { OEMReports } = useSelector((state: any) => state);
    const { loading = true, pagination = {}, entities: reports = [] } = OEMReports || {};
    const { lastPage = 0, pageSize = defaultPageSize } = pagination || {};
    const [page, setPage] = React.useState(0);
    const [limit, setLimit] = React.useState(defaultPageSize);
    const [sortBy, setSortBy] = React.useState("-created_at");

    const [filters, setFilters] = React.useState({} as any);

    const formattedReports: IProps[] = reports?.length ? reports.map(formatTheReport || []) : [];

    return (
        <div>
            <div className="w-full flex justify-end">
                <Menu as="div" className="relative inline-block text-left mx-4 mb-3">
                    <div>
                        <Menu.Button className="flex items-center text-cerulean-700 hover:text-cerulean-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                            <span className="sr-only">Open options</span>
                            <svg
                                className="w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            <span className={"ml-1 tracking-wider font-bold"}>Filters</span>
                        </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            style={{ width: "300px" }}
                            className="border border-gray-100 min-w-64 z-20 origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                            <div className="p-2">
                                <div className="w-full">
                                    <div className={"-mb-4"}></div>
                                    <div className={"flex flex-col gap-2"}>
                                        <OEMProgram
                                            client={{} as IClient}
                                            onValueChange={(event) =>
                                                setFilters({
                                                    ...filters,
                                                    report_param_oem_program: event.target.value
                                                })
                                            }
                                        />
                                    </div>

                                    <div>
                                        <Label className={"-mb-4 mt-4"} label={"Status"} />
                                        <SelectField
                                            name={"processing"}
                                            value={filters?.processing_status ?? ""}
                                            handleChange={(event: any) =>
                                                setFilters({
                                                    ...filters,
                                                    processing_status: event.value
                                                })
                                            }
                                            options={[
                                                { value: "100", label: "CREATED" },
                                                { value: "200", label: "QUEUED" },
                                                { value: "300", label: "PROCESSING" },
                                                { value: "400", label: "COMPLETED" },
                                                { value: "500", label: "VERIFYING" },
                                                { value: "600", label: "VERIFIED" },
                                                { value: "800", label: "FAILED" },
                                                { value: "900", label: "CANCELLED" }
                                            ]}
                                        />
                                    </div>

                                    <div>
                                        <Label className={"-mb-4 mt-4"} label={"Sort By"} />
                                        <SelectField
                                            name={"sort_by"}
                                            value={sortBy}
                                            handleChange={(event: any) => setSortBy(event.value)}
                                            options={[
                                                { label: "Report Date", value: "-report_param_from_date" },
                                                { label: "Created Date", value: "-created_at" },
                                                { label: "Completed Date", value: "-processing_ended_at" }
                                            ]}
                                        />
                                    </div>

                                    <div className={"mt-4 flex justify-between"}>
                                        <Button
                                            onClick={() => {
                                                setPage(1);
                                                onFetchReports(
                                                    {
                                                        page: 0,
                                                        pageSize: limit
                                                    },
                                                    { sort: sortBy, filter: filters }
                                                );
                                            }}
                                            styleType={"primary"}
                                            type={"button"}
                                        >
                                            Apply Filters
                                        </Button>

                                        <Button
                                            onClick={() => {
                                                setPage(1);
                                                setFilters({});
                                                onFetchReports(
                                                    {
                                                        page: 0,
                                                        pageSize: limit
                                                    },
                                                    { sort: sortBy, filter: {} }
                                                );
                                            }}
                                            styleType={"secondary"}
                                            type={"button"}
                                        >
                                            Clear Filters
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>

            <ReactTable
                ref={tableRef}
                className="v1 -striped rounded overflow-hidden"
                data={formattedReports}
                columns={columns}
                loading={loading}
                manual={true}
                resizable={false}
                sortable={false}
                noDataText={!loading && "No Reports Found"}
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                defaultPageSize={pageSize}
                onPageSizeChange={(pageSize: number) => setLimit(pageSize)}
                onPageChange={(p: number) => setPage(p)}
                pages={lastPage}
                page={page}
                pageSize={limit}
                onFetchData={(state: any) =>
                    onFetchReports({ page, pageSize: state.pageSize }, { sort: sortBy, filter: filters })
                }
                LoadingComponent={() => loading && <TableLoader message={"Loading..."} />}
            />
        </div>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onFetchReports: (state: ITableState, urlFilterOptions: any) => {
            const { page, pageSize } = state;
            console.log({ page, pageSize }, urlFilterOptions);
            dispatch(fetchReportList({ ...urlFilterOptions, limit: pageSize, page: page + 1 }));
        },
        createReportJob: (reportFormValues: IReportFormValues) => {
            dispatch(Actions.createReportJob(reportFormValues));
        },
        reportHandler: (reportFormValues: IReportFormValues) => {
            dispatch(Actions.createReportJob(reportFormValues));
        }
    };
};
const mapStateToProps = (state: any) => {
    return {
        reports: state?.OEMReports?.entities ?? {},
        isSubmitting: state?.OEMReports?.isSubmitting ?? false,
        currentUser: state.currentUser,
        loading: state?.OEMReports?.loading ?? true
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportList);
