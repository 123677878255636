import { call, put, takeLatest } from "redux-saga/effects";
import { callApi, getPagedResults } from "../middleware/api";
import schemas from "../middleware/schemas/OEMReports";
import * as Actions from "../actions/OEMReportActions";
import Constants from "../constants/OEMReportConstants";
import { addFlashMessage } from "../actions/flashMessageActions";
import moment from "moment";
import buildUrl from "../utils/UrlUtils";

interface IFetchReportListsResponse {
    result: {
        data: any;
    };
    entities: any;
}

export function* fetchReportLists({ payload }: any) {
    const limit = payload?.limit ?? 15;
    delete payload?.limit;
    const url = buildUrl("/report-jobs", { ...payload, include: "user" });
    try {
        const response: IFetchReportListsResponse = yield call(getPagedResults, url, schemas.OEM_REPORTS, limit);

        response.entities.OEMReports = response.result.data.map(
            (originalIndex: number) => response.entities.OEMReports[originalIndex]
        );

        if (response.entities.OEMReports) {
            yield put(Actions.fetchReportListSuccess(response));
        } else {
            yield put(Actions.fetchReportListSuccessEmpty({}));
        }
    } catch (error) {
        const errorMessage = error.errors ? error.errors.text["0"] : error.exceptionMessage;
        yield put(Actions.fetchReportListsFailure(errorMessage));
    }
}

export function* reportHandler({ payload }: any) {
    let start = moment(payload.startDate);
    let end = moment(payload.endDate);
    let reportType = payload.reportType.search("monthly") !== -1 ? "months" : "days";
    let diff = end.diff(start, reportType as any);

    yield put(addFlashMessage({ type: "warning", text: `Creating ${diff + 1} reports` }));
    for (let index = 0; index <= diff; index++) {
        yield call(createReportJob, { payload });
        payload.startDate = start.add(1, reportType as any).format("YYYY-MM-DD");
    }
}

interface ICreateReportJobResponse {
    result: any;
}

export function* createReportJob({ payload }: any) {
    const url = `/report-jobs`;
    try {
        const response: ICreateReportJobResponse = yield call(callApi, url, schemas.OEM_REPORT, "POST", {
            report_type: payload.reportType,
            report_param_oem_program: payload.oemProgram,
            report_param_from_date: payload.startDate
        });
        yield put(Actions.createReportJobSuccess(response.result));
    } catch (error) {
        const errorMessage = Object.values(error.errors)[0] as string;
        yield put(addFlashMessage({ type: "danger", text: errorMessage }));
        yield put(Actions.createReportJobFailure(error.errors));
    }
}

function* OEMReportsSagas() {
    yield takeLatest(Constants.FETCH_REPORT_LIST, fetchReportLists);
    yield takeLatest(Constants.CREATE_REPORT_JOBS, reportHandler);
}

export default OEMReportsSagas;
